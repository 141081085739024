/*
*	Author: Travolgi
*	Name: Visit
*	Version: 1.0.0
*	Created: 24/01/2023
*	Last update: 10/05/2024
*/

/* Reset */
* {
	padding: 0;
	margin: 0;
	border: 0;
	background: none;
	list-style: none;
	outline: none;
	box-sizing: border-box;
}

:root {
	--white: #ffffff;
	--light: #faebf6;
	--black: #283e41;
	--primary: #a40081;
	--secondary: #169fa9;
	--shadow: rgba(0,0,0,.2);
}

html,
body {
	scroll-behavior: smooth;
}

body {
	background-color: var(--white);
	color: var(--black);
	font-family: 'Josefin Sans', sans-serif;
	font-display: swap;
	font-weight: 300;
	font-size: 1.1rem;
	line-height: 1.5;
}

h1,
h2,
h3,
h4 {
	font-family: 'Lobster Two', serif;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1.4rem;
}
h1 {
	font-size: 4.5rem;
	color: var(--primary);
}
h2 {
	font-size: 2.6rem;
	margin-bottom: 1.8rem;
}
h3 {
	font-size: 1.8rem;
	color: var(--secondary);
}
h4 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}

p:not(:last-child) {
	margin-bottom: 1.5rem;
}

a {
	color: var(--primary);
	text-decoration: none;
	transition: .3s ease-in-out;
}
a.more {
	font-weight: 400;
	letter-spacing: .15rem;
	text-transform: uppercase;
}
a:hover {
	color: var(--secondary);
}

img {
	width: 100%;
}

.btn {
	padding: 1rem 2rem;
	color: var(--white);
	background-color: var(--primary);
}
.btn:hover {
	background-color: var(--secondary);
}

form {
	width: 100%;
	min-width: 25rem;
	padding: 2rem;
	background-color: var(--light);
	box-shadow: .3rem .3rem 1rem var(--shadow);
}
form > *:not(:last-child) {
	margin-bottom: 1rem;
}
input, input[type="date"], select, textarea {
	display: block;
	width: 100%;
	padding: .8rem 1rem;
	font-family: 'Josefin Sans', sans-serif;
	font-size: 1.15rem;
	background-color: var(--white);
	box-shadow: 0 .1rem .2rem var(--shadow);
	transition: .5s ease-in-out;
}
input[type="submit"] {
	font-family: 'Lobster Two', serif;
	font-size: 1.5rem;
	text-transform: uppercase;
	background-color: var(--primary);
	color: var(--white);
}
input[type="date"] {
   -webkit-appearance: textfield;
   -moz-appearance: textfield;
	min-width: 100%;
	min-height: 2.5rem;
}
input[type='date']:after {
   color: var(--black);
   content: attr(placeholder);
}

ul.list {
	margin-bottom: 2rem;
}
ul.list li {
	margin-left: 1.5rem;
	margin-bottom: .5rem;
	list-style: disc;
}

iframe {
	width: 100%;
	height: 25rem;
	box-shadow: .3rem .3rem 1rem var(--shadow);
}


/* Utilities */
article {
	padding: 1.5rem;
	background-color: var(--light);
	box-shadow: .3rem .3rem 1rem var(--shadow);
}

.container {
	max-width: 72rem;
	padding-inline: 2rem;
	margin-inline: auto;
}

.grid {
	display: grid;
	place-items: center;
	grid-template-columns: repeat(2, 1fr);
}
.gtc-1-2 {
	grid-template-columns: 1fr 2fr;
}
.gtc-2-1 {
	grid-template-columns: 2fr 1fr;
}
.gtc-3 {
	grid-template-columns: repeat(3, 1fr);
}
.gtc-4 {
	grid-template-columns: repeat(4, 1fr);
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.space-around { justify-content: space-around; }
.align-items-start { align-items: start; }
.self-start { align-self: start; }

.g-1 { gap: 1rem; }
.g-2 { gap: 2rem; }
.g-3 { gap: 3rem; }
.g-4 { gap: 4rem; }

.pt-3 { padding-top: 3rem; }
.pt-6 { padding-top: 6rem; }
.pb-2 { padding-bottom: 2rem; }
.pb-6 { padding-bottom: 6rem; }
.mb-3 { margin-bottom: 3rem !important; }
.mbk-2 { margin-block: 2rem; }
.mbk-3 { margin-block: 3rem; }

.w-100 { width: 100%; }

.f-serif {
	font-family: 'Lobster Two', serif;
	font-size: 1.5rem;
}
.fs-md { font-size: 1.3rem; }
.fw-600 { font-weight: 600; }
.txt-white { color: var(--white) !important; }
.txt-primary { color: var(--primary); }
.txt-success { color: rgb(0, 179, 0); }
.txt-danger { color: rgb(226, 0, 0); }
.txt-right { text-align: right; }
.txt-center { text-align: center; }

.bg-blur {
	padding: 1.5rem 2rem;
	backdrop-filter: blur(.35rem);
}

.visible { display: block !important; }
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	border: 0;
}


/* Header */
header {
	display: grid;
	place-items: center;
	grid-template-columns: 8rem 1fr 10rem;
	gap: 2rem;
	width: 100%;
	padding: .5rem 2.5rem;
	box-shadow: 0 0 .3rem var(--shadow);
	color: var(--black);
}
header a.w-100 {
	line-height: 0;
}

button.nav-toggle {
	display: none;
}

nav#navbar ul a {
	color: var(--black);
	text-transform: uppercase;
}
nav#navbar ul a:hover {
	color: var(--primary);
}

time p {
	margin: 0 !important;
}
time img {
	width: 2.5rem;
}


/* Slider & Carousel */
section.slider {
	min-height: 95vh;
	padding: 1rem;
	background-image: unset;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: var(--white);
	text-shadow: 0 0 2rem var(--light);
	transition: background-image 1s ease-in-out;
}
section.slider p {
	max-width: 40rem;
	margin-bottom: 0;
}
section.slider form {
	max-width: 30rem;
	margin-bottom: 0;
}

.carousel {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 38rem;
	background-image: unset;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	transition: background-image 1s ease-in-out;
}

section.slider button.prev, 
section.slider button.next,
.carousel button.prev, 
.carousel button.next {
	position: absolute;
	width: 3rem;
	aspect-ratio: 1;
	background-image: url(./imgs/arrow.svg);
	background-repeat: no-repeat;
	background-position: center;
	opacity: .8;
	transition: .3s ease-in-out;
}
section.slider button.prev,
.carousel button.prev {
	transform: rotate(180deg);
}
section.slider button.prev:hover, 
section.slider button.next:hover,
.carousel button.prev:hover, 
.carousel button.next:hover {
	opacity: 1;
}

section.slider button.prev, 
section.slider button.next {
	right: 1rem;
	top: 50%;
}
section.slider button.prev {
	left: 1rem;
	right: unset;
}

.btn-side-left button.prev, 
.btn-side-left button.next,
.btn-side-right button.prev, 
.btn-side-right button.next {
	left: 0;
	bottom: 0;
	background-color: var(--secondary);
	background-size: 35%;
}
.btn-side-left button.next {
	left: 3rem;
}

.btn-side-right button.prev,
.btn-side-right button.next {
	left: unset;
	right: 3rem;
}
.btn-side-right button.next {
	right: 0;
}


/* Custom */
.loading {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--light);
}

.dropcap {
	font-family: 'Lobster Two', serif;
	position: relative;
	float: left;
	margin-right: .8rem;
	font-size: 6rem;
	color: var(--primary);
	font-weight: 600;
	line-height: .8;
}

.box-light {
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	padding: 4rem;
	background-color: var(--light);
}

.headtrip {
	aspect-ratio: 4/1;
	padding-inline: 8%;
	object-fit: cover;
}


/* Pricing */
.pricing {
	width: 100%;
	padding: 2rem;
	padding-bottom: 3rem;
	font-size: 1.3rem;
}
.pricing h2 {
	font-size: 2rem;
	padding-bottom: 1.5rem;
	border-bottom: .2rem solid var(--secondary);
}
.pricing h3 {
	font-size: 5rem;
	margin-bottom: 3rem;
	color: var(--primary);
}
.pricing h3 small {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 1.2rem;
	font-weight: 400;
	color: var(--black);
}
.pricing ul {
	padding-bottom: 2rem;
	margin-bottom: 3rem;
	border-bottom: .2rem solid var(--secondary);
}
.pricing ul li {
	list-style: disc;
	margin-left: 1.5rem;
	margin-bottom: .5rem;
}


/* Whatsapp and gotop buttons */
a#wachat {
	position: fixed;
	display: block;
	height: 4rem;
	width: 4rem;
	bottom: 6rem;
	right: 1.5rem;
	border-radius:100%;
	background: #25d366 url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M3.516 3.516c4.686-4.686 12.284-4.686 16.97 0 4.686 4.686 4.686 12.283 0 16.97a12.004 12.004 0 01-13.754 2.299l-5.814.735a.392.392 0 01-.438-.44l.748-5.788A12.002 12.002 0 013.517 3.517zm3.61 17.043l.3.158a9.846 9.846 0 0011.534-1.758c3.843-3.843 3.843-10.074 0-13.918-3.843-3.843-10.075-3.843-13.918 0a9.846 9.846 0 00-1.747 11.554l.16.303-.51 3.942a.196.196 0 00.219.22l3.961-.501zm6.534-7.003l-.933 1.164a9.843 9.843 0 01-3.497-3.495l1.166-.933a.792.792 0 00.23-.94L9.561 6.96a.793.793 0 00-.924-.445 1291.6 1291.6 0 00-2.023.524.797.797 0 00-.588.88 11.754 11.754 0 0010.005 10.005.797.797 0 00.88-.587l.525-2.023a.793.793 0 00-.445-.923L14.6 13.327a.792.792 0 00-.94.23z'/%3E%3C/svg%3E") 50% no-repeat;
	background-size: 60%;
	z-index: 999;
	animation: fadeIn ease 4s;	
}
a#wachat .wamessage {
	visibility: hidden;
	position: fixed;
	bottom: 6.8rem;
	right: 6rem;
	width: 13.5rem;
	font-size: 1rem;
	padding-block: .3rem;
	border-radius: 1rem;
	text-align: center;
	box-shadow: 0 0 4px 2px var(--shadow);
	background-color: var(--white);
	z-index: 999;
}
a#wachat:hover .wamessage { visibility: visible; }

button.gotop {
   display: none;
   position: fixed;
   right: 2rem;
   bottom: 2rem;
	width: 2.5rem;
	aspect-ratio: 1;
	background-image: url(./imgs/arrow.svg);
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(-90deg);
	filter: brightness(0);
	opacity: .6;
   animation: fadeIn ease-out 1.5s;
   transition: .4s ease-in-out;
   z-index: 999;
}
button.gotop:hover {
   opacity: 1;
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}


/* Footer */
footer {
	padding-top: 6rem;
	padding-inline: 8%;
	background-color: var(--light);
}
footer img {
	max-width: 12rem;
}
footer ul li {
	margin-bottom: .5rem;
}
footer svg {
	width: 1.5rem;
	height: 1.5rem;
}

/*** Responsive mobile ***/
@media (max-width: 72em) {
   button.gotop {
      right: 1rem;
      bottom: 1rem;
   }
}


@media (max-width: 1080px) {
	.gtc-1-2 {
		grid-template-columns: 1.5fr 2fr;
	}
	.gtc-2-1 {
		grid-template-columns: 2fr 1.5fr;
	}

	/* Header */
	header {
		grid-template-columns: 8rem 1fr 1.6rem;
		align-items: center;
		gap: 1.5rem;
		padding: 1rem;
	}
	header time.flex {
		flex-direction: row;
	}

	button.nav-toggle {
		display: block;
		position: absolute;
		right: 1rem;
		top: 2.3rem;
		background-image: url(./imgs/icon-hamburger.svg);
		background-repeat: no-repeat;
		background-position: center;
		width: 1.6rem;
		aspect-ratio: 1;
		z-index: 999;
	}
	button.nav-toggle[aria-expanded="true"] {
		position: fixed;
		background-image: url(./imgs/icon-close.svg);
	}

	nav#navbar {
		position: fixed;
		inset: 0 0 0 30%;
		padding: min(20rem, 15vh) 2rem;
		background: var(--light);
		box-shadow: 0 .3rem .3rem var(--shadow);
		z-index: 99;
		transform: translateX(100%);
		transition: transform .5s ease-in-out;
	}
	nav#navbar[data-visible="true"] {
		transform: translateX(0);
	}
	nav#navbar ul.flex {
		flex-direction: column;
		align-items: start;
	}
}
@media (max-width: 44em) {
	h1,
	h2,
	h3,
	h4 {
		line-height: 1;
	}

	form  {
		min-width: 100%;
		padding: 1.5rem;
	}

	.grid,
	.gtc-1-2,
	.gtc-2-1,
	.gtc-3,
	.gtc-4 {
		grid-template-columns: 1fr;
	}
	.flex {
		flex-direction: column;
	}
	.flex-col {
		flex-direction: row;
	}
	.space-around {
		justify-content: space-around;
	}

	/* Slider */
	section.slider {
		padding-top: 6rem;
		margin-bottom: 18rem;
	}
	section.slider form {
		margin-bottom: -18rem;
	}
	section.slider button.prev, 
	section.slider button.next {
		top: 60%;
		width: 2rem;
	}

	/* Pricing */
	.pricing h3 {
		font-size: 4.3rem;
	}
	.pricing h3 small {
		display: block;
	}
}
